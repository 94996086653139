
import { defineComponent, ref } from 'vue'
import ApiService from "@/core/services/ApiService"
import store from "@/store"
import { mapGetters } from "vuex"
import { ReportData, Stat } from "@/core/interfaces/MonthlyPerformance"
import _ from "lodash"

export default defineComponent({
  name: 'App',
  props: {
    route_params: {
      type: Object,
      required: true
    }
  },
  data() {
    const reportData = {} as ReportData
    const reportStats = [] as Array<Stat>

    const monthYear = ((date) => {
      const d = new Date(date.substring(0, 4), date.substring(5, 7) - 1)
      return d.toLocaleString('default', { month: 'long', year: 'numeric' })
    })

    return {
      monthYear,
      reportData,
      reportStats
    }
  },
  async created() {
    this.reportData = await this.getReport(this.route_params) as ReportData
    this.buildReportData(this.reportData)
  },
  computed: {
    ...mapGetters(["activeAccountInfo"]),
  },
  methods: {
    async getReport(route_params) {
      const accountNum = store.getters.activeAccountInfo.id
      const yearMonth = ref(route_params.yearMonth).value

      ApiService.setHeader()
      let resp = {}
      try {
        resp = await ApiService.get(`/accounts/${accountNum}/monthly-performance/${yearMonth}`)
      } catch (e) {
        console.error("Problem setting primary location", e)
      }

      return resp
    },
    bppSum(bpp, statName) {
      return _.values(bpp).reduce((sum, cur) => {
        if (!cur.totals[statName]) return sum
        return sum + cur.totals[statName]
      }, 0)
    },
    buildReportData(reportData) {
      const reportAttrs = reportData.data.data.attributes.report
      const statsAttribute = reportAttrs.ga4.totals
      const bppSumCalls = this.bppSum(reportAttrs.bpp, "call_clicks")
      const bppSumClicks = this.bppSum(reportAttrs.bpp, "website_clicks")

      this.reportStats = [
        {
          name: "Total Leads",
          value: statsAttribute.total_leads
        },
        {
          name: "Total Visitors",
          value: statsAttribute.total_visitors
        },
        {
          name: "SEO Leads",
          value: statsAttribute.organic_leads
        },
        {
          name: "SEO Visitors",
          value: statsAttribute.organic_visitors
        },
        {
          name: "Google Business Calls",
          value: bppSumCalls
        },
        {
          name: "Google Business Website Clicks",
          value: bppSumClicks
        }
      ]

      this.reportStats = this.reportStats.filter(stat => stat.value)
      this.reportStats.forEach(stat => {
        stat.value = this.formatNumber(stat.value)
      })
    },
    formatNumber(num) {
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }
  }
})
